import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config.js';

let matrixInterval;
let matrixRecalc = false;
const matrixCanvas = document.getElementById('waterfall');
let matrixWidth = matrixCanvas.width;

function matrixEffect() {
  if (matrixRecalc) return;

  if (typeof matrixInterval !== 'undefined' && matrixWidth === matrixCanvas.parentElement.offsetWidth) {
    return;
  }
  
  clearInterval(matrixInterval);
  matrixRecalc = true;
  matrixWidth = matrixCanvas.parentElement.offsetWidth;
  
  const hexToRgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };
  
  const ctx = matrixCanvas.getContext('2d');
  
  // Wipe the canvas
  ctx.clearRect(0, 0, matrixCanvas.width, matrixCanvas.height);
  
  // Set canvas to window size
  matrixCanvas.width = matrixCanvas.parentElement.offsetWidth;
  matrixCanvas.height = matrixCanvas.parentElement.offsetHeight;
  
  // Convert canvas width from px to vw
  const elVw = matrixCanvas.width / document.documentElement.clientWidth * 100;
  
  // Characters to include in waterfall
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZ'.repeat(5)
    + '0123456789'.repeat(10)
    + 'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン';

  // Font size in vw
  const fontSize = 4000 / matrixCanvas.width;
  
  // Calculate pixel size of font at 1vw
  const fontVw = matrixCanvas.width / elVw;

  // Convert vw font size to pixels
  const fontSizePx = fontSize * fontVw;
  
  // Calculate font height in vw from font size
  const fontSizeHeight = fontSizePx * 1.2;
  
  // Calculate number of columns
  const columns = elVw / fontSize;
  
  // Pre-populate matrix
  let drops = Array.from({ length: columns }).fill(matrixCanvas.height);
  
  const draw = () => {
    // Get theme colors
    const theme = resolveConfig(tailwindConfig).theme;
    const topColor = theme.colors.secondary.dark;
    const bottomColor = theme.colors.secondary.DEFAULT;
    
    // Replicate gradient found in the home page background
    const grd = ctx.createLinearGradient(0, 0, 0, matrixCanvas.height);
    
    // Using 10% opacity, the character gets dissolved every 10 iterations
    grd.addColorStop(0, hexToRgba(topColor, 0.1));
    grd.addColorStop(1, hexToRgba(bottomColor, 0.1));
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, matrixCanvas.width, matrixCanvas.height);
    
    // Iterate over the drops
    for (var i = 0; i < drops.length; i++) {
      // Get a random character from the list
      const text = letters[Math.floor(Math.random() * letters.length)];
      
      // Get the text color and font
      ctx.fillStyle = hexToRgba(theme.colors.primary.DEFAULT);
      const family = theme.fontFamily.sans[0];
      ctx.font = `${fontSizePx}px ${family}`;
      
      // Calculate and fill the letter's coordinates based on the font width and height
      ctx.fillText(text, i * fontSizePx, drops[i] * fontSizeHeight);
      
      // Move on to the next drop
      drops[i]++;

      // Roll for a chance to reset the drop height
      if (drops[i] * fontSizeHeight > matrixCanvas.height && Math.random() > 0.95) {
        drops[i] = 0;
      }
    }
  }

  const matrixSleep = 70;

  // Time in ms to wait until waterfall is complete
  matrixInterval = setInterval(draw, matrixSleep);
  matrixRecalc = false;
}

matrixEffect();
window.addEventListener('resize', matrixEffect);

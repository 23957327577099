const plugin = require('tailwindcss/plugin');

module.exports = {
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    fontFamily: {
      sans: ['"IBM Plex Mono"', 'monospace'],
    },
    colors: {
      primary: {
        DEFAULT: '#00FFE0',
        dark: '#009986',
        light: '#CEFFF8',
      },
      secondary: {
        DEFAULT: '#4B1A8A',
        dark: '#200347',
        light: '#5f3893',
      },
      tertiary: {
        DEFAULT: '#FF0086',
        dark: '#d30070',
        light: '#fc3aa1',
      },
      white: '#ffffff',
      black: '#000000',
      light: '#dddddd',
      dark: '#191919',
      gray: '#808080',
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
    plugin(function ({ addComponents, theme }) {
      addComponents({
        '.btn': {
          padding: theme('spacing.1') + ' ' + theme('spacing.3'),
          marginLeft: theme('spacing.1'),
          marginRight: theme('spacing.1'),
          borderRadius: theme('borderRadius.DEFAULT'),
          color: theme('colors.black'),
          fontSize: theme('fontSize.base'),
          '&:focus': {
            outline: 'none',
          }
        },
        '.btn-white': {
          backgroundColor: theme('colors.white'),
          '&:hover': {
            backgroundColor: theme('colors.light'),
          },
          '&:disabled': {
            backgroundColor: theme('colors.light'),
            color: theme('colors.gray'),
          },
          '&:focus': {
            boxShadow: '0 0 0 2px ' + theme('colors.tertiary.DEFAULT'),
          },
        },
        '.btn-black': {
          backgroundColor: theme('colors.black'),
          color: theme('colors.white'),
          '&:hover': {
            backgroundColor: theme('colors.dark'),
          },
          '&:disabled': {
            backgroundColor: theme('colors.dark'),
            color: theme('colors.light'),
          },
        },
        '.btn-primary': {
          backgroundColor: theme('colors.primary.DEFAULT'),
          '&:hover': {
            backgroundColor: theme('colors.primary.light'),
          },
          '&:disabled': {
            backgroundColor: theme('colors.primary.dark'),
          },
          '&:focus': {
            boxShadow: '0 0 0 2px ' + theme('colors.tertiary.DEFAULT'),
          },
        },
        '.btn-secondary': {
          color: theme('colors.white'),
          backgroundColor: theme('colors.secondary.DEFAULT'),
          '&:hover': {
            backgroundColor: theme('colors.secondary.light'),
          },
          '&:disabled': {
            backgroundColor: theme('colors.secondary.dark'),
          },
          '&:focus': {
            boxShadow: '0 0 0 2px ' + theme('colors.tertiary.DEFAULT'),
          },
        },
        '.btn-tertiary': {
          backgroundColor: theme('colors.tertiary.DEFAULT'),
          color: theme('colors.white'),
          '&:hover': {
            backgroundColor: theme('colors.tertiary.light'),
          },
          '&:disabled': {
            backgroundColor: theme('colors.tertiary.dark'),
          },
          '&:focus': {
            boxShadow: '0 0 0 2px ' + theme('colors.white'),
          },
        },
        '.focus-primary:focus': {
          boxShadow: '0 0 0 2px ' + theme('colors.primary.DEFAULT'),
          outline: 'none',
        },
        '.focus-tertiary:focus': {
          boxShadow: '0 0 0 2px ' + theme('colors.tertiary.DEFAULT'),
          outline: 'none',
        },
      });
    }),
  ],
};
